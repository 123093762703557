import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import oopsIcon from '../../data/icons/oops.svg'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="u-standard-margins yourbox__errorscreen">
      <img
        src={oopsIcon}
        className="yourbox__errorscreen__icon"
        alt="cart icon"
      />
      <h1>
        Oops, there's no page here.
        <br />
        You might want to check the url again or navigate somewhere else on the site.
      </h1>
      <div className="u-margin-bottom-huge"></div>
    </div>
  </Layout>
)

export default NotFoundPage
